import {useNavigate} from "react-router-dom";
import React, {useEffect} from "react";
import s from "./Pages.module.css";
import PostPreview from "../components/PostPreview/PostPreview";
import Avatar1 from '../assets/img/Avatar1.png'
import {posts} from "../store/data";

export const PostsPage = () => {
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    return (
        <div style={{paddingBottom: "48px"}}>
            <h1 className={s.projectsTitle}>Мой блог</h1>

            <div className={s.blogPage_posts}>
                {
                    posts.map(post => {
                        return <PostPreview key={"postCard"+post.id} post={post}/>
                    })
                }
            </div>
        </div>
    )
}
