import {useNavigate, useParams} from "react-router-dom";
import React, {useEffect} from "react";
import s from "./Pages.module.css";
import PostPreview from "../components/PostPreview/PostPreview";
import Avatar1 from '../assets/img/Avatar1.png'
import {posts} from "../store/data";
import PostGenerator from "../components/PostGenerator/PostGenerator";

export const PostPage = () => {
    const {postID} = useParams();
    let navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);



    if(typeof posts[postID] === 'undefined' || !posts[postID].hasOwnProperty("article"))
        return <p>Статья не найдена</p>

    return (
        <div style={{paddingBottom: "48px"}}>
            <h1 className={s.projectsTitle}>{posts[postID].articleTitle}</h1>

            <PostGenerator blocks={posts[postID].article}/>
            {/*<div className={s.blogPage_posts}>*/}
            {/*    {*/}
            {/*        posts.map(post => {*/}
            {/*            return <PostPreview key={"postCard"+post.id} post={post}/>*/}
            {/*        })*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    )
}
