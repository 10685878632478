import Project1 from "../assets/img/Project1.png";
import Project16 from "../assets/img/Project16.png";
import Project14 from "../assets/img/Project14.png";
import Project15 from "../assets/img/Project15.png";
import Project7 from "../assets/img/Project7.png";
import Project13 from "../assets/img/Project13.png";
import Project12 from "../assets/img/Project12.jpg";
import Project5 from "../assets/img/Project5.png";
import Project6 from "../assets/img/Project6.png";
import Project8 from "../assets/img/Project8.png";
import Project9 from "../assets/img/Project9.png";
import Project10 from "../assets/img/Project10.png";
import Project17 from "../assets/img/Project17.png";
import Project11 from "../assets/img/Project11.png";
import s from "../pages/Pages.module.css"
import classNames from "classnames";
import Avatar1 from "../assets/img/Avatar1.png";

const APIsrc = "https://www.ren-design.ru/api/portfolio/1.0/"
const projectImagesAPI = APIsrc + "images/projects/"
const projectVideosAPI = APIsrc + "video/"

export const projects = [
    {
        id: 0,
        category: "Коммерческий",
        name: "АвтоМагШина",
        year: 2022,
        shortDescription: "Интернет-магазин шин и дисков с автоматическим подбором по авто, каталогом продукции, шинным калькулятором и онлайн заказом для компании из Магнитогорска",
        previewImage: Project1,
        tags: ["PHPShop", "1C", "Интернет-магазин"],
        projectURL: "https://automagshina.ru/",
        description: (
            <div>
                <p>Интернет-магазин шин и дисков с автоматическим подбором по авто, каталогом продукции, шинным
                    калькулятором и онлайн заказом для компании из Магнитогорска</p>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Установка и настройка PHPShop
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна сайта и реализация его через систему шаблонов в PHPShop
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Подключение базы подбора шин и дисков по авто TyresAddict
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Настройка фильтров шин и дисков для каталога товаров
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка модуля 1С для ежедневной выгрузки товаров на сайт
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Подключение ежедневной выгрузки товаров на сайт из 1С и сервиса 4tochki
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "PHPShop, HTML, CSS, PHP, 1C (Для учёта товаров)"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "Сайт",
                content: "https://automagshina.ru/"
            }
        ],
        images: [projectImagesAPI + "AutoMagShinaSRC1.png", projectImagesAPI + "AutoMagShinaSRC2.png", projectImagesAPI + "AutoMagShinaSRC3.png", projectImagesAPI + "AutoMagShinaSRC4.png", projectImagesAPI + "AutoMagShinaSRC5.png"]
    },
    {
        id: 1,
        category: "Коммерческий",
        name: "УралМебель",
        year: 2022,
        shortDescription: "Веб-сайт с каталогом товаров и адресами магазинов для группы компаний, занимающихся мягкой мебелью, из Магнитогорска",
        previewImage: Project16,
        tags: ["WordPress", "Elementor", "Интернет-магазин"],
        projectURL: "https://уралмебель74.рф/",
        description: (
            <div>
                <p>Веб-сайт с каталогом товаров и адресами магазинов для группы компаний, занимающихся мягкой
                    мебелью,
                    из Магнитогорска</p>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте:</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Редизайн сайта, реализация через конструктор Elementor
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка новых баннеров для главной страницы
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Подготовка изображений товаров к публикации
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "WordPress, Elementor, HTML, CSS"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "Сайт",
                content: "https://уралмебель74.рф"
            }
        ],
        images: [projectImagesAPI + "UralMebelSRC1.png", projectImagesAPI + "UralMebelSRC2.png", projectImagesAPI + "UralMebelSRC3.png", projectImagesAPI + "UralMebelSRC4.png"]
    },
    {
        id: 2,
        category: "Коммерческий",
        name: "РекАрт",
        year: 2023,
        shortDescription: "Веб-сайт для рекламного агенства в Магнитогорске",
        previewImage: Project11,
        tags: ["WordPress", "Elementor"],
        projectURL: "https://рекарт.рф/",
        description: (
            <div>
                <p>Веб-сайт для рекламного агенства в Магнитогорске</p>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Обновление плагинов WordPress c 2011 года
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка сайта по макетам от заказчика
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "WordPress, Elementor, HTML, CSS"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "Сайт",
                content: "https://рекарт.рф"
            }
        ],
        images: [projectImagesAPI + "RecArtSRC1.png", projectImagesAPI + "RecArtSRC2.png", projectImagesAPI + "RecArtSRC3.png", projectImagesAPI + "RecArtSRC4.png"]
    },
    {
        id: 3,
        category: "Разработан на хакатоне",
        name: "BeMyVoice",
        year: 2023,
        shortDescription: "Сервис для распознавания и озвучивания русской жестовой речи, а также транскрибации звуков с компьютера и окружающего мира",
        previewImage: Project14,
        tags: ["React", "Python", "C#", "AI"],
        gitURL: "https://github.com/CatDevelop/BeMyVoice",
        description: (
            <div>
                <p>Сервис для распознавания и озвучивания русской жестовой речи, а также транскрибации
                    звуков с компьютера и окружающего мира</p><br/>
                <p>Проект разработан в рамках хакатона от Сбера и УрФУ "Код города 300" и стал победителем в кейсе
                    "ЧитAI жесты"</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Захват видео с камеры
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Превращение жестов в текст с помощью нейросети от Сбера SLT2TXT
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Озвучка текста с помощью нейросети от Сбера Salute Speech
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Захват звука с компьютера и преобразование его в текст (Salute Speech) с определением эмоции
                        собеседника: позитивная, нейтральная, негативная
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка архитектуры приложения и схемы взаимодействия микросервисов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна приложения
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка десктопного приложения на React+Electron
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание API на Python для подключения нейронной сети SLT2TXT к приложению
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Electron, Python (+ C#)"
            },
        ],
        video: projectVideosAPI + "BeMyVoice-Demo.mp4",
        images: [projectImagesAPI + "BeMyVoiceSRC1.png", projectImagesAPI + "BeMyVoiceSRC2.png", projectImagesAPI + "BeMyVoiceSRC3.png", projectImagesAPI + "BeMyVoiceSRC4.png"],
    },
    {
        id: 4,
        category: "Сервис внутреннего использования",
        name: "P-1",
        year: "В разработке",
        shortDescription: "Сервис для эффективного управления проектом внутри команды. Постановка задач, менеджер багов, блочный редактор статей, построитель схем - теперь в одном приложении",
        previewImage: Project15,
        tags: ["React", "Redux", "PHP"],
        description: (
            <div>
                <p>Сервис для эффективного управления проектом внутри команды. Постановка задач, менеджер
                    багов, блочный редактор статей, построитель схем - теперь в одном приложении</p><br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление задачами (Создание, редактирование, удаление, несколько видов отображения,
                        создание кастомного вида по фильтрам и сортировкам)
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление багами, связь с задачами
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление статьями (Объединение статей в серии, блочный редактор статей, интеграция с
                        построителем схем)
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Построитель схем
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимаюсь на проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка архитектуры приложения и схемы взаимодействия микросервисов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна приложения
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка десктопного приложения на React+Electron
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Redux, Electron, PHP"
            },
        ],
        images: [projectImagesAPI + "P1SRC1.png", projectImagesAPI + "P1SRC2.png", projectImagesAPI + "P1SRC3.png", projectImagesAPI + "P1SRC4.png", projectImagesAPI + "P1SRC5.png", projectImagesAPI + "P1SRC6.png", projectImagesAPI + "P1SRC7.png"]
    },
    {
        id: 5,
        category: "Учебный (для партнёров)",
        name: "HR USSCxUDV",
        year: 2023,
        shortDescription: "Сервис для постановки квартальных целей HR-сотрудников группы компаний УЦСБ",
        previewImage: Project7,
        tags: ["React", "Redux", "C#"],
        description: (
            <div>
                <p>Сервис для постановки квартальных целей HR-сотрудников группы компаний УЦСБ.</p>
                <p>Проект выполнен в рамках курса "Проектный практикум" для 2 курса УрФУ</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление задачами
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление итогами по задачам
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Фильтр и сортировка каталога
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разделение сервиса на роли: главный руководитель, руководитель, сотрудник
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Выгрузка задач и итогов в Excel
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Участие в разработке архитектуры сервиса
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка frontend'a сервиса на React
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Связывание frontend и backend частей
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Redux, HTML, CSS"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "URFU-HR-USSC",
                content: "https://github.com/CatDevelop/URFU-HR-USSC"
            }
        ],
        video: projectVideosAPI + "HRUSSC-Demo.mp4",
        images: [projectImagesAPI + "HrUsscSRC1.png", projectImagesAPI + "HrUsscSRC2.png", projectImagesAPI + "HrUsscSRC3.png", projectImagesAPI + "HrUsscSRC4.png", projectImagesAPI + "HrUsscSRC5.png", projectImagesAPI + "HrUsscSRC6.png", projectImagesAPI + "HrUsscSRC7.png"],
    },
    {
        id: 6,
        category: "Учебный",
        name: "Portfolio Hub",
        year: 2023,
        shortDescription: "Сервис для создания web-портфолио IT-специалиста с помощью блочного редактора (Можно добавлять свои проекты)",
        previewImage: Project13,
        tags: ["React", "Redux", "PHP"],
        projectURL: "https://portfoliohub.ru/",
        gitURL: "https://github.com/CatDevelop/URFU-portfolio-hub",
        description: (
            <div>
                <p>Сервис для создания web-портфолио IT-специалиста.</p>
                <p>Проект выполнен в рамках курса "Проектный практикум" для 1 курса УрФУ</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание своего портфолио
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Заполнение информации о своей профессиональной деятельности
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Добавление своих проектов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Группировка проектов по категориям
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Добавление своего резюме
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Комментирование и оценивание чужих проектов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр каталога всех пользователей сервиса (С поиском и фильтрацией по тегам)
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка архитектуры сервиса
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна сервиса
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка frontend'a сервиса на React
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Написание рестов для взаимодействия с БД
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Связывание frontend и backend частей
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Redux, HTML, CSS"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "Сайт",
                content: "https://portfoliohub.ru/"
            },
            {
                id: 2,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "URFU-portfolio-hub",
                content: "https://github.com/CatDevelop/URFU-portfolio-hub"
            }
        ],
        video: projectVideosAPI + "PortfolioHub-Demo.mp4",
        images: [projectImagesAPI + "PortfolioHubSRC1.png", projectImagesAPI + "PortfolioHubSRC2.png", projectImagesAPI + "PortfolioHubSRC3.png", projectImagesAPI + "PortfolioHubSRC4.png", projectImagesAPI + "PortfolioHubSRC5.png", projectImagesAPI + "PortfolioHubSRC6.png"]
    },
    {
        id: 7,
        category: "GameDev",
        name: "HeroReturn",
        year: 2023,
        shortDescription: "Пиксельная игра в жанре Lazy-RPG (Призывай юнитов - они сделают всё за тебя)",
        previewImage: Project12,
        tags: ["С#", "MonoGame"],
        gitURL: "https://github.com/CatDevelop/HeroReturnC",
        description: (
            <div>
                <p>Пиксельная игра в жанре Lazy-RPG (Призывай юнитов - они сделают всё за тебя).</p>
                <p>Проект выполнен в рамках курса "Основый программирования" для 1 курса УрФУ</p>
                <br/>
                <p className={s.projectBadge}>Механики игры</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Улучшение построек
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Улучшение ресурсов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Призыв собирателей, дровосеков и воинов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Система волн врагов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Постепенная разблокировка героев с каждым уровнем
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Система сохранений
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "C#, MonoGame"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "HeroReturnC#",
                content: "https://github.com/CatDevelop/HeroReturnC"
            }
        ],
        video: projectVideosAPI + "HeroReturn-Demo.mp4",
        images: [projectImagesAPI + "HeroReturnSRC1.png", projectImagesAPI + "HeroReturnSRC2.png", projectImagesAPI + "HeroReturnSRC3.png", projectImagesAPI + "HeroReturnSRC4.png", projectImagesAPI + "HeroReturnSRC5.png", projectImagesAPI + "HeroReturnSRC6.jpg"],
    },
    {
        id: 8,
        category: "Учебный",
        name: "Бронирование бассейна УрФУ",
        year: 2023,
        shortDescription: "Сервис для бронирования дорожек в бассейне Уральского федерального университета им. Б. Ельцина",
        previewImage: Project5,
        tags: ["React", "Redux", "PHP"],
        gitURL: "https://github.com/CatDevelop/URFU-pool-booking",
        description: (
            <div>
                <p>Сервис для бронирования дорожек в бассейне УрФУ</p>
                <p>Проект выполнен по заказу студентов 4 курса УрФУ для дисциплины "Проектный практикум"</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр таблицы забронированных дорожек
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание новой записи бронирования, включая данные для оплаты
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Уведомления об изменениях в базе данных
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка архитектуры системы
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Написание рестов для взаимодействия с БД
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Связывание frontend и backend частей
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Redux, HTML, CSS, PHP"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "URFU-pool-booking",
                content: "https://github.com/CatDevelop/URFU-pool-booking"
            }
        ],
        images: [projectImagesAPI + "PoolBookingSRC1.png", projectImagesAPI + "PoolBookingSRC2.png", projectImagesAPI + "PoolBookingSRC3.png"]
    },
    {
        id: 9,
        category: "Учебный",
        name: "Система учёта книг",
        year: 2021,
        shortDescription: "Автоматизированная информационная система для библиотеки Академического лицея города Магнитогорска",
        previewImage: Project6,
        tags: ["HTML", "CSS", "Python", "PHP"],
        gitURL: "https://github.com/CatDevelop/Library",
        description: (
            <div>
                <p>Автоматизированная информационная система для библиотеки Академического лицея города
                    Магнитогорска</p>
                <p>Проект выполнен в рамках всероссийского конкурса научно-технологических проектов "Большие
                    вызовы"</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление книгами
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Фильтр и сортировка каталога
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание отчёта из электронного каталога в MS Excel
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание QR-кодов для книг
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Автоматическое обновление приложения с сервера
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка архитектуры системы
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Написание рестов для взаимодействия с БД
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна система
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка десктопного приложения на Contruct 2
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Связывание frontend и backend частей
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "HTML, CSS, Python, PHP"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "Library",
                content: "https://github.com/CatDevelop/Library"
            }
        ],
        images: [projectImagesAPI + "LibrarySRC1.png", projectImagesAPI + "LibrarySRC2.png", projectImagesAPI + "LibrarySRC3.png"]
    },
    {
        id: 10,
        category: "Учебный",
        name: "ITS-Economy",
        year: 2022,
        shortDescription: "Электронный сервис управления банковским счётом виртуальной валюты для выездной профильной смены в IT-школе",
        previewImage: Project8,
        tags: ["React", "Redux", "PHP"],
        gitURL: "https://github.com/CatDevelop/ITS-Economy",
        description: (
            <div>
                <p>Электронный сервис управления банковским счётом виртуальной валюты для выездной профильной смены
                    в
                    IT-школе</p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр текущего баланса
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр истории операций
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Перевод на другой счёт
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Заморозка счетов (Для администратора)
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр текущего баланса всех пользователей (Для администратора)
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, Redux, HTML, CSS, PHP"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "ITS-Economy",
                content: "https://github.com/CatDevelop/ITS-Economy"
            }
        ],
        images: [projectImagesAPI + "ITSeconomySRC1.png", projectImagesAPI + "ITSeconomySRC3.png", projectImagesAPI + "ITSeconomySRC4.png", projectImagesAPI + "ITSeconomySRC2.png"]
    },
    {
        id: 11,
        category: "Учебный",
        name: "Визуализатор деревьев",
        year: 2021,
        shortDescription: "Приложение для визуализации работы со структурой данных \"Деревья\" (Создание, добавление, удаление, поиск элементов, обходы)",
        previewImage: Project9,
        tags: ["С++", "Windows Forms"],
        gitURL: "https://github.com/CatDevelop/IT-School/tree/main/SecondCourse/TreesVisualization",
        description: (
            <div>
                <p>Приложение для визуализации работы со структурой данных "Деревья" </p>
                <p>Проект выполнен в качестве выпускной работы за второй курс обучения в школе программирования</p>
                <br/>
                <p className={s.projectBadge}>Возможности визуализатора</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание бинарных и AVL деревьев
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Вставка нового элемента
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Поиск элемента
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Удаление элемента
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Обход дерева
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Проверка на симметричность
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "C++, WinForms"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "TreesVisualization",
                content: "https://github.com/CatDevelop/IT-School/tree/main/SecondCourse/TreesVisualization"
            }
        ],
        images: [projectImagesAPI + "TreesVisualizationSRC1.png"]
    },
    {
        id: 12,
        category: "Учебный",
        name: "Калькулятор графиков",
        year: 2020,
        shortDescription: "Приложение для визуализации графиков и их зависимости от коэффициентов",
        previewImage: Project10,
        tags: ["С++", "TXLib"],
        gitURL: "https://github.com/CatDevelop/IT-School/tree/main/SecondCourse/TreesVisualization",
        description: (
            <div>
                <p>Приложение для визуализации графиков и их зависимости от коэффициентов</p>
                <p>Проект выполнен в качестве выпускной работы за первый курс обучения в школе программирования</p>
                <br/>
                <p className={s.projectBadge}>Возможности визуализатора</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Отрисовка графиков: прямая, модуль, парабола, ветвь параболы, кубическая парабола,
                        радиоволны,
                        синус, тангенс, арктангенс
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Просмотр формулы и коэффициентов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление коэффициентами в формуле
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Управление масштабом графика
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "С++, TXLib"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "GraphCalculator",
                content: "https://github.com/CatDevelop/IT-School/tree/main/FirstCourse/GraphCalculator"
            }
        ],
        images: [projectImagesAPI + "GraphicsSRC1.png", projectImagesAPI + "GraphicsSRC2.png"]
    },
    {
        id: 13,
        category: "Коммерческий",
        name: "Изучение РЖЯ",
        year: "2023 - в разработке",
        shortDescription: "Сервис для изучения русского жестового языка с использованием модели распознавания жестов для тренировок",
        previewImage: Project17,
        tags: ["React", "Python", "C#", "AI"],
        gitURL: "https://github.com/CatDevelop/Teaching-RSL/tree/88_exhibition_stand__dev",
        projectURL: "https://pincode-project.ru/",
        description: (
            <div>
                <p>Сервис для изучения русского жестового языка с использованием модели распознавания жестов для
                    тренировок</p>
                <br/>
                <p>
                    Демонстрационные варианты сервиса участвовали в: III Конгрессе молодых учёных в Сочи-Сириусе (28-30 ноября 2023),
                    выставке "Форум будущего" в Екатеринбург-экспо (5-6 декабря 2023). А также стенд стоит в офисе Сбера (Екатеринбург)
                </p>
                <br/>
                <p>Проект выполнен в рамках дисциплины "Проектный практикум" студентов 2-3 курсов Уральского
                    федерального университета имени первого Президента России Б. Н. Ельцина
                </p>
                <br/>
                <p className={s.projectBadge}>Возможности сервиса</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Изучение жестов: теория + закрепление практическими заданиями
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Проверка знаний с помощью распознавания жестов, показываемых обучающимся в камеру
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Словарь жестов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Создание пользовательских тренировок
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Система уровней и достижений
                    </li>
                </ul>
                <br/>
                <p className={s.projectBadge}>Чем занимался в проекте</p>
                <ul className={s.nestedList}>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка схемы пользовательского пути сервиса
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка дизайна система
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка схемы взаимодействия бэкенда и фронтенда
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка API для подключения к модели распознования
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка демонстрационных стендов для выставок и форумов
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка раздела обучение - задания + история прохождения уровней
                    </li>
                    <li className={classNames(s.nestedListItem, s.nestedListItemUnordered)}>
                        Разработка блока распознавания для тренировок
                    </li>
                </ul>
            </div>
        ),
        informationBlocks: [
            {
                id: 0,
                blockType: "Text",
                blockTitle: "Стек",
                content: "React, TypeScript, Python (+ C#)"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "GitHub",
                linkText: "Teaching-RSL",
                content: "https://github.com/CatDevelop/Teaching-RSL/tree/stand"
            },
            {
                id: 1,
                blockType: "Link",
                blockTitle: "Сайт",
                linkText: "pincode-project.ru",
                content: "https://pincode-project.ru"
            }
        ],
        images: [projectImagesAPI + "RSLSRC1.png", projectImagesAPI + "RSLSRC2.png", projectImagesAPI + "RSLSRC3.png", projectImagesAPI + "RSLSRC4.png", projectImagesAPI + "RSLSRC5.png"]
    }
]

export const projectOrder = [0, 1, 2, 13, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

export const posts = [
    {
        id: 0,
        avatar: Avatar1,
        nickname: "CatDev 💻",
        post: "<div><h1>Привет, я Макс!</h1><p>Это мой блог, здесь я рассказываю о своих увлечениях, экспериментах в программировании и истории создания своих проектов.</p><p>Если вам интересны IT-технологии или же моя жизнь, заглядывайте иногда на эту страницу 😉</p></div>",
        attachment: {
            type: "image",
            link: "https://www.ren-design.ru/api/portfolio/1.0/images/posts/Image5.jpg"
        },
        location: "Москва",
        date: "10.09.2023",
        isPinned: true
    },
    // {
    //     id: 1,
    //     avatar: Avatar1,
    //     nickname: "CatDev 💻",
    //     post: "<div><h1>Как мы хакатон от Сбера выиграли</h1><p>В рамках хакатона за неделю успели сделать прототип приложения для ПК, которое позволит слабослышащим удобно общаться в онлайн созвонах и конференциях.</p><p>О том, как мы придумали идею, не спали несколько суток, облажались на презентации и многом другом - читайте далее.</p></div>",
    //     attachment: {
    //         type: "image",
    //         link: "https://www.ren-design.ru/api/portfolio/1.0/images/posts/Image2.jpg"
    //     },
    //     location: "Екатеринбург, Бизнес-центр Neboplaza",
    //     date: "19.08.2023",
    //     articleTitle: "Хакатон \"Код города 300\"",
    //     article: [{
    //         "type": "paragraph",
    //         "data": {"text": "В августе этого года Сбер совместно с Уральским федеральным университетом провели 24-часовой хакатон в новом Технохабе в столице Среднего Урала. Мероприятие было приурочено трехсотлетию города Екатеринбург. Кейсы были направлены на решение задач в сфере экологии, спортивных и культурных мероприятий, а также инклюзии."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "image",
    //         "data": {
    //             "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Фото10.jpg",
    //             "caption": "",
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "В  отборочном этапе хакатона подали заявки 239 человек. Участникам необходимо было пройти тестирование разной направленности, а также написать рассуждение о развитии города и стратегии ESG (Принципы деятельности компании, основанные на защите окружающей среды, создание благоприятных социальных условий, добросовестном отношении с сотрудниками и клиентами и надлежащем корпоративном управлении)."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "AnyButton",
    //         "data": {
    //             "link": "https://www.ren-design.ru/api/portfolio/1.0/files/ESG.pdf",
    //             "text": "Скачать наше сочинение о стратегии ESG"
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "К  участию в  очном этапе были приглашены 138 участников (38  команд). На выбор предоставили 3 кейса, для которых нужно было разработать программное обеспечение. «Привет, Екатеринбург!» – сервис о городских мероприятиях, «ЧитAI жесты» – приложение для распознавания непрерывной русской жестовой речи, «Экология большого города» – сервис, через который можно передавать сигналы о состоянии окружающей среды."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "header",
    //         "data": {"text": "Наш кейс", "level": 2},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Наша команда выбрала очень интересный кейс - \"ЧитAI жесты\". Главной задачей было разработать концепцию, идею и прототип продукта, направленного на помощь слабослышащим людям. Наша идея базировалась на использовании модели распознавания русского жестового языка SLT2Text от Сбера, которая преобразовывает видеопоток или файл с жестами в текст. "},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Для разработки нам предоставили GitHub репозиторий с моделью распознавания: <a href=\"https://github.com/ds-hub-sochi/SLT-EKB\">https://github.com/ds-hub-sochi/SLT-EKB</a>"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Продукт был нацелен на решение проблемы коммуникации между слабослышащими и теми, кто использует звучащую речь. Организаторы не ставили ограничений на формат реализации, но требовали, чтобы он решал одну из следующих проблем: облегчение коммуникации слабослышащих людей в повседневной жизни, досуге и развлечениях, или в образовании. "},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "В отличии от других кейсов, под нас выделили целый этаж в офисе, где расположились 14 команд.&nbsp;Это дало нам возможность постоянно взаимодействовать не только с техническими экспертами, знакомыми с используемыми технологиями, но и с носителями русского жестового языка. В рамках хакатона было проведено две сессии, в которых мы имели возможность задавать вопросы и обсуждать различные способы реализации нашего продукта."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "header",
    //         "data": {"text": "Хорошая идея - уже половина успеха", "level": 2},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Еще до старта хакатона, наша команда собралась в Discord для обсуждения различных идей сервисов. В тот момент нашему проектному менеджеру, Роме, пришла идея - разработать приложение, которое предоставляло бы синхронную транскрибацию и озвучивание жестов для людей с ограниченными возможностями слуха, а также автоматическую расшифровку разговоров собеседников."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "alert",
    //         "data": {
    //             "type": "primary",
    //             "align": "left",
    //             "message": "Жесты -> текст + озвучка, звук с компьютера -> текст (Субтитры)"
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Суть этой идеи заключается в том, чтобы переводить жесты в текст и озвучивать их, а также конвертировать аудиосигналы с компьютера в текст (создавая субтитры). Особенностью сервиса является его способность захватывать звук не только из конкретных приложений, но и из всей операционной системы. Это решает еще одну актуальную проблему - отсутствие субтитров на некоторых онлайн-платформах для просмотра видео и прослушивания аудио. Наше решение предоставляет универсальные субтитры, которые работают на любых онлайн-сервисах, делая онлайн-встречи более доступными и инклюзивными."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "header",
    //         "data": {"text": "MVP за 24 часа", "level": 2},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "12 августа, на часах 11:00, наша команда с опозданием на торжественное открытие прибывает в технохаб. Не тратя ни минуты, мы занимаем наши места и ожидаем ТЗ от организаторов. Через час все команды получили техническое задание и материалы для разработки. Внимательно ознакомившись со всеми условиями, мы поняли, что изначально придуманная идея подходит как никогда кстати, решение принято твёрдо - реализуем этот сервис!"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Так как наша команда была сформирована задолго до начала хакатона, у нас не возникло проблем с коммуникацией. Мы хорошо знали друг друга, и распределение ролей было ясным: два бэкенд-разработчика, фронтенд-разработчик и проект-менеджер (В его работу включалось создание презентации, аналитическая работа и помощь с нейронкой, так как он был знаком с Python)."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Мы приступили к разработке. Поскольку предоставленная модель была написана на Python, мы решили писать и десктопное приложение на нём же. Я специализируюсь на JS, React и Elctron (Забегу вперёд, на них и будет переписано итоговое приложение), поэтому для меня это было в новинку. На курсах по Python, ещё года 2 назад, упоминался tkinter для построения интерфейсов, взглянув на более продвинутую версию -&nbsp;<a href=\"https://customtkinter.tomschimansky.com/\">CustomTkinter</a>&nbsp;сейчас и проанализировав другие библиотеки для интерфейсов, пришёл к выводу, что это лучшее решение (Как оказалось, у Python вообще беда с графическими интерфейсами - он попросту не предназначен для этого 😆)"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Времени на дизайн, как и дизайнера, не было, поэтому макеты в Figma я рисовал максимально схематично, чтобы просто понять примерную композицию, да и CustomTkinter не позволяет настолько кастомизировать элементы."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "image",
    //         "data": {
    //             "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Демо3.png",
    //             "caption": "Макет в Figma",
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Параллельно моей работе, бэкендеры, Даня и Айнур, начали изучать API необходимых нейросетей (SLT2Text и SaluteSpeech), а также думать над захватом звука с компьютера. Мы перебирали разные сценарии от реализации всего сервиса на Python до запуска дополнительных скриптов на других языках, да, это костыли, но в условиях хакатона у нас просто не было других выходов."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "На часах 18:00 и я... отправляюсь спать. По опыту прошлых хакатонов - на двух я не спал больше 30 часов - сон критически необходим, иначе в 5-8 утра ваша работоспособность упадёт до клацанья мышкой в пустоту. Тем более, часть с захватом камеры и базовая разметка приложения были готовы. Для продолжения работы мне необходимы наработки остальных участников. Я проснулся примерно в 21:30 и сразу начал вливаться в работу. "},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Рома подготовил часть кода для подключение к ChatGPT - это тоже одно из наших преимуществ. Все модели по распознаванию жестов выдают набор слов, например, \"Я Бегать Парк\", мы же хотели видеть связные предложения, которые потом можно озвучить. Совокупность нейросетей позволяет творить удивительные вещи. Если правильно написать промт для ChatGPT, можно получить нужное предложение - \"Я бегаю в парке\"."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "А вот у бэкендеров дела обстояли не так хорошо, подключиться к API им получилось, но создание виртуального микрофона на С# оказалось практически невозможном (Жесты, которые распознавались моделью должны были озвучиваться и передаваться сразу собеседникам по специальному каналу), да и при записи звуков с компьютера файлы создавались, однако звука там не было 😔"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "image",
    //         "data": {
    //             "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Фото2.jpg",
    //             "caption": "",
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Я продолжил работу: подключил отображение распознанных жестов, а после и нормализованного текста. Ребята один за другим уходили спать на диванчики и массажные кресла, в офисе воцарилось сонное царство. Вечерний сон позволил работать всё оставшееся время, вплоть до завершение хакатона. Часов в 6 утрам с Даней мы всё-таки смогли добить запись звука с компьютера, после чего и он ушёл спать, а я - завтракать."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Из всей команды я остался один, у меня стояло несколько задач: реализовать отображение распознанного текста с компьютера, сделать выбор камеры, с которой захватывалось изображение, и нарисовать схему взаимодействия сервиса для презентации. На данном этапе не возникло больших проблем, поэтому, когда проснулись ребята, сервис был практически готов."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "image",
    //         "data": {
    //             "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Схема1.png",
    //             "caption": "Схема режима распознавания жестов",
    //         }
    //     }, {
    //         "type": "image",
    //         "data": {
    //             "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Схема2.png",
    //             "caption": "Схема режима распознавания звука с компьютера",
    //         }
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "В 11:30 утра Рома начал готовить презентацию с докладом, а я делал схемы. Защита была очень близко..."},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "header",
    //         "data": {"text": "Защита в полуфинале", "level": 2},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Организаторы вызвали по одному участнику из каждой команды - выбирали очередь выступления. Нам попалось 7 из 14, середнячок. Приступаем к записи видеодемонстрации нашего продукта, так как время защиты ограничено и мы не можем производить live demo.&nbsp;&nbsp;"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     }, {
    //         "type": "paragraph",
    //         "data": {"text": "Рома устроился в специальной звукоизоляционной комнате, а я приступил к записи. Мы подключились по Яндекс.Телемосту, чтобы имитировать онлайн встречу. Я показывал жесты, а Рома говорил текст, который должна была распознать наша система. Сервис распознавал не так хорошо, как нам хотелось бы, поэтому пришлось потратить около 40 минут, используя несколько попыток - уже даже защиты начались, а мы всё записывали 😅"},
    //         "tunes": {"anyTuneName": {"alignment": "left"}}
    //     },
    //         {
    //             "type": "video",
    //             "data": {
    //                 "url": "https://www.ren-design.ru/api/portfolio/1.0/video/BeMyVoice-Demo2.mp4",
    //                 "caption": "",
    //             }
    //         },
    //         {
    //             "type": "paragraph",
    //             "data": {"text": "Несколько прогонов текста и вот момент X. Вызывают нашу команду. Выступали я и Рома, моей зоной ответственности была техническая часть проекта, схема работы и реализация функционала. Волнение сыграло злую шутку - мы забывали текст, и путались в словах, но несмотря на это, защита прошла вполне удачно."},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "image",
    //             "data": {
    //                 "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Фото8.jpg",
    //                 "caption": "Защита в полуфинале",
    //             }
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": "Из всех защит, я выделил 3 вида проектов в нашем кейсе: обучение жестовому языку, распознавание жестов и инструменты для дообучения моделей."},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": "Осталось ждать результатов, это было самый волнительный момент. Ведущий начинает объявлять двух победителей из каждого кейса, которые доработают свои решения и представят их на дне города. Наш кейс объявляют последним... И мы смогли, мы в финале! 🎉"},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": "Мы были очень удивлены, лично для меня это был первый хакатон, в котором команда смогла пройти в финал. Естественно, были рады, но понимали, что предстоит ещё ооочень много работы."},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "image",
    //             "data": {
    //                 "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/1/Фото3.jpg",
    //                 "caption": "Мы прошли в финал!",
    //             }
    //         }, {
    //             "type": "header",
    //             "data": {"text": "Работа только начинается", "level": 2},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": ""},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "header",
    //             "data": {"text": "Либо мы, либо нас", "level": 2},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": ""},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "header",
    //             "data": {"text": "А что после?", "level": 2},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }, {
    //             "type": "paragraph",
    //             "data": {"text": ""},
    //             "tunes": {"anyTuneName": {"alignment": "left"}}
    //         }],
    // },

    // {
    //     id: 2,
    //     avatar: Avatar1,
    //     nickname: "CatDev 💻",
    //     post: "<div><h1>Статья о модели распознавании + примеры использования</h1></div>",
    //     attachment: {
    //         type: "image",
    //         link: "https://www.ren-design.ru/api/portfolio/1.0/images/posts/Image4.jpg"
    //     },
    //     date: "10.09.2023"
    // },
    {
        id: 1,
        avatar: Avatar1,
        nickname: "CatDev 💻",
        post: "<div><h1>Выставка \"Форум Будущего\"</h1><p>Наша команда впервые демонстрировала разработки общественности, стала экспонентом на выставке, или о том, как мы учили людей русскому жестовому языку - читайте далее.</p></div>",
        attachment: {
            type: "image",
            link: "https://www.ren-design.ru/api/portfolio/1.0/images/posts/Image6.jpg"
        },
        location: "Конгресс-центр МВЦ «Екатеринбург-Экспо»",
        date: "09.12.2023",
        articleTitle: "Выставка \"Форум Будущего\" 2023",
        article: [
            {
                "type": "paragraph",
                "data": {"text": "С 5 по 8 декабря 2023 года в Екатеринбурге при поддержке МинЦифры России и Правительства Свердловской области состоялся первый IT-конгресс и выставка «Форум Будущего», посвященные развитию цифровой индустрии и совершенствованию решений в сфере информбезопасности."},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "id": "gzMr43LXbn",
                "type": "paragraph",
                "data": {
                    "text": "Наша команда смогла поучаствовать в выставке в качестве экспонента с демонстрационным вариантом нашего сервиса \"Изучение русского жестового языка\" (Статью о нём вы можете прочитать <a href=\"https://habr.com/ru/articles/777700/\">здесь</a>). Если кратко, мы используем модель распознавания жестов, чтобы оценивать, правильно ли человек показал жест. На стенде участники могли изучить 5 жестов и попробовать их на практике.<br>"
                },
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "header",
                "data": {"text": "Почему мы решили участвовать в выставке", "level": 2},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {
                    "text": "Наш продукт представляет собой уникальное решение в своей области, которое не только позволяет демонстрировать разработанную технологию - модель распознавания, но и предоставляет возможность для интерактивного взаимодействия - люди в реальном времени могут изучить жесты и уйти с положительными эмоциями. Участие в выставке становится отличной платформой для личной презентации нашего продукта. "
                },
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {
                    "text": "Наша основная цель на выставке - привлечь внимание к важной социальной проблеме, а именно, <b>созданию инклюзивной среды </b>для людей с ограничениями по слуху. Учитывая, что выставка уже привлекла посетителей, заинтересованных в изучении новинок и готовых внимательно слушать и рассматривать предложения, мы видим здесь отличную возможность донести наше послание."
                },
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "header",
                "data": {"text": "Нужно договориться об участии...", "level": 2},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {
                    "text": "Вечером 28 ноября (За неделю до выставки) наш проджект менеджер, Роман, увидел в группе института пост с приглашением. Ему сразу же пришла идея написать организаторам и попробовать договориться об участии. Сроки были сжатыми, но руководство вошло в наше положение и разрешили разместиться. Площадь и оборудование вышли бы в кругленькую сумму, которой у нас, естественно, нет. Организаторы пошли нам на встречу и Рома смог договориться о бесплатном участии (Дада, студенты) с условием использования нашего собственного оборудования. "
                }, "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {"text": "Таким образом, мы смогли стать участниками выставки."},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "id": "TfLi_4jz4z",
                "type": "quote",
                "data": {
                    "text": "Большое спасибо организаторам Форума будущего, всем, кто помогал нам во время выставки и отдельная благодарность Марии Суетиной за предоставленную возможность участия<br>",
                    "caption": "",
                    "alignment": "left"
                }
            }, {
                "type": "header",
                "data": {"text": "Как мы стенд собирали", "level": 2},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {"text": "Собственного оборудования у нас не было тоже 😁, но наш любимый ИРИТ-РТФ УрФУ любезно предоставил \nминимальный набор для бедных студентов - участников выставки: \nтелевизор, подставку под него, вебкамеру, тумбу для ноутбука и кликер.<br>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {"text": "За день до выставки наш институт завозил оборудование для другой части мероприятия - Киберучений, в соседнее здание, откуда мы и забрали наш стартерпак. <br>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Карта.png",
                    "caption": "Маршрут от общественного центра УрФУ до площадки x3",
                }
            },
            {
                "type": "paragraph",
                "data": {"text": "В сообщениях и на карте звучит круто: \"Соседнее здание\", но на самом деле предстоял титанический труд, чтобы доставить всё это до площадки. В общей сложности мы прошли 5.5км.<br>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            },
            {
                "type": "video",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/video/Stand-Travel.mp4",
                    "caption": "",
                }
            }, {
                "type": "paragraph",
                "data": {"text": "Когда стенд был собран, он выглядел, как 🪲 баг на проде, нужно было срочно исправлять - буквально в автобусе, по пути домой, мы согласовали с нашим дизайнером наклейки для тумбочки и заказали их на печать. За 5 минут до закрытия типографии, успели забрать мерчовые футболки с символикой нашей команды и эти наклейки. <br>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            },
            {
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Баг.jpg",
                    "caption": "Баг на проде",
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "Изначально наш стенд был рассчитан под сенсорный экран, но за неимением такого, мне пришлось переделать интерфейс под кликер. Мы решили отказаться от тренировочных заданий и оставили только теорию и практику с распознаванием жестов.&nbsp; <br>"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "header",
                "data": {"text": "Дни выставки", "level": 2},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "paragraph",
                "data": {
                    "text": "В первый день мы приехали пораньше, чтобы настроить оборудование и до конца оформить стенд. Разместили наклейки на тумбочке - вышло \"сомнительно, но окэй\". Протестировали стенд, переписанный под кликер - всё работало хорошо.<br>"
                }, "tunes": {"anyTuneName": {"alignment": "left"}}
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "Пошло волнительное время ожидание первых посетителей нашего небольшого уголка... С первыми посетителями общался Рома, но из-за недосыпа его энтузиазм быстро закончился, после этого разговаривать стал я. Изредка мы менялись, например, на обед.<br>"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "Было довольно тяжело и непривычно целый день завлекать людей к нам, рассказывать каждому о проблеме, которую мы решаем, показывать и подсказывать как работать с нашим стендом. Но эмоции людей, которые радуется, когда их жесты действительно распознаются - заряжали энергией до самого конца.<br>"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "video",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/video/ForumFuture-Description.mp4",
                    "caption": "",
                }
            }, {
                "id": "wx3LKjjAuY",
                "type": "paragraph",
                "data": {
                    "text": "В конце первого дня, мы единогласно решили, что с распознаванием некоторых жестов есть проблемы, \"ты\" и \"я\" очень плохо распознавались на фоне проходящих мимо людей, т.к. датасет модели распознавания записан в формате \"я сижу перед ноутбуком, гляжу в вебку\" - соответственно в таком положении жесты и должны были распознаваться, а не стоя на фоне толпы.<br><br>Во второй день мы добавили настройки для стенда - можно было изменить режим оценивания (Показываем все распознанные слова или только оценку) и количество слов (Можно было убрать слова, которые плохо распознавались). Ожидались важные гости: директор нашего института, крутые спикеры, телеведущие и даже губернатор."
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "id": "hxiD8Nszl_",
                "type": "paragraph",
                "data": {
                    "text": "В этот день было побольше людей, но мы держались уверенно и раздавали свои контакты направо и налево (люди сами их просили).<br>Наш стенд посетил Илья Николаевич Обабков, Павел Львович Резник, Юрий Юрьевич Чернышов и другие, не менее замечательные и крутые, посетители выставки. Получили кучу обратной связи, контактов и просто хороших слов."
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },{
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Photo1.jpg",
                    "caption": "Любимый Илья Николаевич дает обратную связь, а Рома молится на него",
                }
            },
            {
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Photo2.jpg",
                    "caption": "Когда кто-то показывал жесты, сразу образовывались группы заинтересованных",
                }
            },
            {
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Photo3.jpg",
                    "caption": "Случайный посетитель показывает жесты и радуется результату ✨",
                }
            },
            {
                "type": "image",
                "data": {
                    "url": "https://www.ren-design.ru/api/portfolio/1.0/images/posts/2/Photo4.jpg",
                    "caption": "",
                }
            },
            {
                "id": "boMtwYJCnB",
                "type": "header",
                "data": {"text": "Немного статистики", "level": 2},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "id": "JAVpRgDGt-",
                "type": "paragraph",
                "data": {"text": "<b>За два дня выставки: </b>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "id": "mtEEDotemk",
                "type": "list",
                "data": {
                    "style": "unordered",
                    "items": [{
                        "content": "Мероприятие посетили 3.5 тысячи человек из 100 городов России",
                        "items": []
                    }, {
                        "content": "Было представлено 42 экспонента из 10 регионов",
                        "items": []
                    }, {
                        "content": "На 36 секциях выступили 188 спикеров",
                        "items": []
                    }, {
                        "content": "Подписано 15 соглашений о сотрудничестве между крупными компаниями и государственными организациями",
                        "items": []
                    }]
                }
            }, {
                "type": "paragraph",
                "data": {"text": "<b>На нашем стенде:</b>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "list",
                "data": {
                    "style": "unordered",
                    "items": [{
                        "content": "132 человека изучили 5 жестов на РЖЯ",
                        "items": []
                    }, {
                        "content": "Система распознала более 3 тысяч жестов",
                        "items": []
                    }, {
                        "content": "QR-код на наш Habr отсканирован более 40 раз",
                        "items": []
                    }, {"content": "Мы отстояли 17.5 часов, демонстрируя наш продукт", "items": []}]
                }
            }, {
                "type": "paragraph",
                "data": {"text": "<b>Расходы:</b><br>"},
                "tunes": {"anyTuneName": {"alignment": "left"}}
            }, {
                "type": "list",
                "data": {
                    "style": "unordered",
                    "items": [{
                        "content": "Наклейки - 180р",
                        "items": []
                    }, {
                        "content": "Транспорт до места проведения и обратно - 3856р",
                        "items": []
                    }, {"content": "Мерч (Футболки на всю команду) - 6240р", "items": []}]
                }
            }, {
                "type": "header",
                "data": {
                    "text": "Советы на будущее",
                    "level": 2
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "list",
                "data": {
                    "style": "unordered",
                    "items": [
                        {
                            "content": "Сделать визитки (Раздаточный материал) - чтобы люди запоминали нас и могли взять наши контакты в более удобном варианте",
                            "items": []
                        },
                        {
                            "content": "Лучше оформить стенд, уделить этому больше внимания - чтобы привлекать больше внимания и презентовать нашу команду серьезнее, чем просто чуваки с телевизором и тумбочкой.",
                            "items": []
                        },
                        {
                            "content": "Заранее продумать, где будем брать оборудование и как будем транспортировать его до места проведения.",
                            "items": []
                        }
                    ]
                }
            },
            {
                "type": "header",
                "data": {
                    "text": "Выводы",
                    "level": 2
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "Мы получили огромный опыт, когда поучаствовали в этой выставке. Было приятно осознавать, что мы достигли такого уровня, когда стоим рядом с IT-гигантами Екатеринбурга."
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "<b>Если вы студент </b>- не бойтесь попытаться поучаствовать в подобном мероприятии, а вдруг прокатит?)"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {

                "type": "paragraph",
                "data": {
                    "text": "<b>Если вы представитель серьезной компании</b> - научите завлекать и развлекать людей на своих стендах, ведь они пришли на выставку не за вашими брошюрками?)"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "<b>Если вы участник выставки</b> - не стесняйтесь проявлять благодарность и эмоции на хороших стендах, ребята всё-таки стараются :)<br>"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
            {
                "type": "paragraph",
                "data": {
                    "text": "<b>Если вы организатор выставок</b> - берите пример, давайте шанс заряженным ноунеймам - это ооочень приятный жест доброй воли, который запомнят не только экспоненты, но и участники выставки.<br>"
                },
                "tunes": {
                    "anyTuneName": {
                        "alignment": "left"
                    }
                }
            },
        ]
    },
    // {
    //     id: 1,
    //     avatar: Avatar1,
    //     nickname: "CatDev 💻",
    //     post: "<p>Всем приветawdawd</p>",
    //     attachment: {
    //         type: "video",
    //         link: "https://www.ren-design.ru/api/portfolio/1.0/images/posts/Image2.jpg"
    //     },
    //     location: "Екатеринбург",
    //     haveArticle: true,
    //     date: "10.09.2023",
    //     isPinned: true
    // },
]
